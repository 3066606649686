<template>

  <b-form
    method="POST"
    autocomplete="off"
    class="auth-permission-form"
    @submit.prevent="saveUserTypePermission"
  >
    <b-row>
      <b-col md="12">
        <b-form-group
          label="Users Type"
          label-for="id"
        >
          <v-select
            id="id"
            v-model="userTypeSelected"
            name="inputname"
            class="p-0 border-0"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            placeholder="Select"
            label="group_desc"
            :reduce="userType => userType.group_id"
            :options="user"
            @input="changeUser"
          />
        </b-form-group>

      </b-col>
      <b-col
        v-for="per in permission"
        :key="per.trngroupid"
        md="4"
        class="py-1"
      >
        <b-card
          class="h-100"
          :header="per.trngrouptitle"
        >

          <b-form-checkbox
            v-for="trans in per.transaction"
            :key="trans.trnid"
            v-model="permissionSelected"
            class="my-1"
            :name="per.trngrouptitle+trans.trnid"
            :value="trans.trnid"
          >
            <span> {{ trans.trnname }} </span>
          </b-form-checkbox>

        </b-card>

      </b-col>
      <!-- reset and submit -->
      <b-col
        cols="12"
        class="text-center"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1"
        >
          Submit
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          variant="outline-secondary"
        >
          Reset
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow, BCol, BCard, BForm, BFormGroup, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '../../store'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormCheckbox,
    vSelect,
    BCard,
    BButton,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      user: [],
      permission: [],
      userTypeSelected: '',
      permissionSelected: [],
    }
  },
  mounted() {
    store.dispatch('userPermission/getPermissionSettings')
      .then(response => {
        if (response.data.code === '200') {
          this.permission = response.data.data
        }
      })
    store.dispatch('userPermission/getUsers')
      .then(response => {
        if (response.data.code === '200') {
          this.user = response.data.data
        }
      })
  },
  methods: {
    changeUser() {
      store.dispatch('userPermission/getUserTypePermissions', { adminuserid: this.userTypeSelected })
        .then(response => {
          if (response.data.code === '200') {
            this.permissionSelected = response.data.data
          }
        })
    },
    saveUserTypePermission() {
      store.dispatch('userPermission/saveUserTypePermissions', { adminuserid: this.userTypeSelected, transactionIds: this.permissionSelected })
        .then(response => {
          if (response.data.code === '200') {
            this.user = response.data.data
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `${response.data.msg}`,
                icon: 'Success',
                variant: 'success',
                text: '',
              },
            })
          }
        }).catch(e => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${e.msg}`,
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
  .card {
    max-height: 350px;
    margin-bottom: 0;
  }
  .card-header {
    background: linear-gradient(to right , rgba(99, 99, 248, 0.199), rgba(99, 99, 248, 0.199), rgba(255, 128, 255, 0.281));
    font-weight: bold;
  }
  .card-body {
    overflow: auto;
    margin-bottom: 1.5rem;
  }
  .card-body::-webkit-scrollbar {
    display: none;
  }
</style>
